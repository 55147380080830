<template>
  <div class="promodes-page">
    <h1>Ваши промокоды</h1>
    <ul v-if="promocodes.length" class="promodes-page__list">
      <li v-for="(item, i) in promocodes" :key="i" @click="copyCode(item.code)">
        {{ item.code }} - {{ item.book.title }}
      </li>
    </ul>
    <span v-else>У вас нет подарочных промокодов</span>
  </div>
</template>

<script>
import GET from "/src/graphql/my_gift_codes.graphql";
export default {
  name: "PromocodesPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: GET,
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        store.state.promocodes = data.my_gift_codes;
      })
      .catch(() => {});
    let queries = [];
    if (store.state.apollo_token && store.state.user) {
      queries.push(store.dispatch("cart", { apollo: apollo.defaultClient }));
      queries.push(store.dispatch("user_books", { apollo: apollo.defaultClient }));
    }
    await Promise.all(queries);
  },
  methods: {
    copyCode(text) {
      navigator.clipboard.writeText(text);
    },
  },
  computed: {
    promocodes() {
      return this.$store.state.promocodes;
    },
  },
};
</script>

<style lang="stylus">
.promodes-page {
  padding: 30px
  display flex
  flex-direction column
  gap 15px

  +below(480px) {
    padding: 30px 15px
  }

  h1 {
    margin: 0
  }

  &__list {
    list-style none
    display flex
    flex-direction column
    gap 15px
    font-size: 1.125rem
    padding: 0 20px

    & li {
      cursor: pointer;
    }
  }
}
</style>
